$(function () {
    initNav();
    initCarousel();
    initOrder();
    initCustomerEdit();
    initLightbox();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        initNav();
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .toggle_menu');
    $nav = $('header nav');
    $li = $nav.find('li');

    // Reset
    $btn_navigation.unbind();
    $li.has('ul').unbind();
    $li.has('ul').children('a').each(function(){
        $(this).unbind();
    });

    // mobile
    if ($(window).width() < 992) {
        $btn_navigation.click(function (e) {
            e.stopPropagation();
            e.preventDefault();

            $nav.slideToggle();
        });

        $li.find('a.active').next('ul').show();

        $li.has('ul').children('a').on('click', function (e) {
            nextul = $(this).next('ul');

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if (!$(ul).is($(nextul))) {
                    $(this).prev('a').children('span').removeClass('toggle')
                    $(ul).slideUp();
                }
            });

            if ($(this).next('ul').is(':hidden')) {
                e.preventDefault();
                $(this).next('ul').slideDown();
                $(this).children('span').addClass('toggle')
            }
        });
    } else {
        $li.has('ul').hover(function (e) {

            $(this).children('a').next('ul').stop().slideDown();
            $(this).children('a').children('span').addClass('toggle')

        }, function (e) {

            $(this).children('a').next('ul').stop().slideUp();
            $(this).children('a').children('span').removeClass('toggle')

        });
    }
}

function initCarousel() {
    if ($('#carousel').length > 0) {
        $('#carousel').slick({
            dots: true,
            arrows: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 720,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initOrder() {
    if ($('#order').length > 0) {
        $('#order .method').click(function (e) {
            $('#order .method').removeClass('active');
            $(this).addClass('active');
            $('input[name=payment_method]').val($(this).data('description'));
        });

        if ($('input[name=payment_method]').val()) {
            $('#order .method[data-description=' + $('input[name=payment_method]').val() + ']').click();
        }
    }
}

function initCustomerEdit() {
    if ($('#customer_edit')) {
        $('#password_toggle').on('click', function (e) {
            e.preventDefault();
            $('#password_reset').slideToggle();
        });
    }
}

function initLightbox() {
    $('.lightbox').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
}

// FORMATTERS

function inschrijvenFormatter(value, row) {
    if(row.is_available == true){
        return '<div class="text-sm-right">' +
            '<a href="' + row.link + '" title="Inschrijven" class="btn btn-primary">Inschrijven</a>' +
            '</div>';
    } else {
        return '<div class="text-sm-right">' +
            '<div title="Inschrijven" class="btn btn-danger no-pointer">Volgeboekt</div>' +
            '</div>';
    }
}